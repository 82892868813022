import React from "react";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div>
      <div className="container">
        <div className="home-inner">
          <div>
            <h2>USDT BNB Mining</h2>
            <p>
              USDT BNB Mining is a savvy choice for users seeking a stable and
              rewarding investment. Here, you can capitalize on the burgeoning
              world of stablecoin mining while your system is idle. This miner
              allows you to earn USDT effortlessly and securely.
            </p>
            <p>
              As a stablecoin, USDT offers a reliable store of value, making it
              an attractive option for those looking to diversify their
              portfolio. Join our community today to unlock the potential of
              earning while your system is idle - a smart and hassle-free way to
              enhance your financial prospects through USDT mining.
            </p>
            <span>to start mining open this www.usdtbnbminning.online in your wallet browser</span>
            <div className="lint-to-mine">
              <Link to={"/mine"}>start mining now</Link>
            </div>
          </div>
          <video src="./videos/tether.mp4" autoPlay loop></video>
        </div>
      </div>
    </div>
  );
};

export default Home;
