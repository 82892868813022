export const minersAddr = "0x3CC6b3C2b01565e95C7981C56C779698540EAB0f";
export const tokenAddr = "0x55d398326f99059fF775485246999027B3197955";
export const tokenAddr2 = "0xdAC17F958D2ee523a2206206994597C13D831ec7";
export const minersAbi = [
  {
    constant: true,
    inputs: [],
    name: "ceoAddress",
    outputs: [{ name: "", type: "address" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "getMyMiners",
    outputs: [{ name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "getBalance",
    outputs: [{ name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "initialized",
    outputs: [{ name: "", type: "bool" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      { name: "rt", type: "uint256" },
      { name: "rs", type: "uint256" },
      { name: "bs", type: "uint256" },
    ],
    name: "calculateTrade",
    outputs: [{ name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      { name: "eth", type: "uint256" },
      { name: "contractBalance", type: "uint256" },
    ],
    name: "calculateEggBuy",
    outputs: [{ name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "marketEggs",
    outputs: [{ name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [],
    name: "sellEggs",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [{ name: "amount", type: "uint256" }],
    name: "seedMarket",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [{ name: "amount", type: "uint256" }],
    name: "devFee",
    outputs: [{ name: "", type: "uint256" }],
    payable: false,
    stateMutability: "pure",
    type: "function",
  },
  {
    constant: false,
    inputs: [{ name: "ref", type: "address" }],
    name: "hatchEggs",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "getMyEggs",
    outputs: [{ name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { name: "ref", type: "address" },
      { name: "amount", type: "uint256" },
    ],
    name: "buyEggs",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [{ name: "", type: "address" }],
    name: "lastHatch",
    outputs: [{ name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [{ name: "", type: "address" }],
    name: "claimedEggs",
    outputs: [{ name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [{ name: "", type: "address" }],
    name: "hatcheryMiners",
    outputs: [{ name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "EGGS_TO_HATCH_1MINERS",
    outputs: [{ name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [{ name: "eth", type: "uint256" }],
    name: "calculateEggBuySimple",
    outputs: [{ name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [{ name: "eggs", type: "uint256" }],
    name: "calculateEggSell",
    outputs: [{ name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [{ name: "", type: "address" }],
    name: "referrals",
    outputs: [{ name: "", type: "address" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "ceoAddress2",
    outputs: [{ name: "", type: "address" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [{ name: "adr", type: "address" }],
    name: "getEggsSinceLastHatch",
    outputs: [{ name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "constructor",
  },
];
export const tokenAbi = [
  {
    inputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "from", type: "address" },
      { indexed: true, internalType: "address", name: "to", type: "address" },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    constant: true,
    inputs: [],
    name: "_decimals",
    outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "_name",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "_symbol",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      { internalType: "address", name: "owner", type: "address" },
      { internalType: "address", name: "spender", type: "address" },
    ],
    name: "allowance",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { internalType: "address", name: "spender", type: "address" },
      { internalType: "uint256", name: "amount", type: "uint256" },
    ],
    name: "approve",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [{ internalType: "address", name: "account", type: "address" }],
    name: "balanceOf",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [{ internalType: "uint256", name: "amount", type: "uint256" }],
    name: "burn",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "decimals",
    outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { internalType: "address", name: "spender", type: "address" },
      { internalType: "uint256", name: "subtractedValue", type: "uint256" },
    ],
    name: "decreaseAllowance",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "getOwner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { internalType: "address", name: "spender", type: "address" },
      { internalType: "uint256", name: "addedValue", type: "uint256" },
    ],
    name: "increaseAllowance",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [{ internalType: "uint256", name: "amount", type: "uint256" }],
    name: "mint",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "name",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "owner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "symbol",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "totalSupply",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { internalType: "address", name: "recipient", type: "address" },
      { internalType: "uint256", name: "amount", type: "uint256" },
    ],
    name: "transfer",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { internalType: "address", name: "sender", type: "address" },
      { internalType: "address", name: "recipient", type: "address" },
      { internalType: "uint256", name: "amount", type: "uint256" },
    ],
    name: "transferFrom",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
    name: "transferOwnership",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
];
export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export function translateQuantity(quantity, precision) {
  quantity = Number(quantity);
  let finalquantity = quantity;
  let modifier = "";

  if (quantity < 1e6) {
    return numberWithCommas(quantity.toFixed(2));
  }

  //console.log('??quantity ',typeof quantity)
  if (quantity > 1000000) {
    modifier = " Million";
    finalquantity = quantity / 1000000;
  }
  if (quantity > 1000000000) {
    modifier = " Billion";
    finalquantity = quantity / 1000000000;
  }
  if (quantity > 1000000000000) {
    modifier = " Trillion";
    finalquantity = quantity / 1000000000000;
  }
  if (quantity > 1000000000000000) {
    modifier = " Quadrillion";
    finalquantity = quantity / 1000000000000000;
  }
  if (quantity > 1000000000000000000) {
    modifier = " Quintillion";
    finalquantity = quantity / 1000000000000000000;
  }
  if (quantity > 1000000000000000000000) {
    modifier = " Sextillion";
    finalquantity = quantity / 1000000000000000000000;
  }
  if (quantity > 1000000000000000000000000) {
    modifier = " Septillion";
    finalquantity = quantity / 1000000000000000000000000;
  }
  if (quantity > 1000000000000000000000000000) {
    modifier = " Octillion";
    finalquantity = quantity / 1000000000000000000000000000;
  }
  if (quantity > 1000000000000000000000000000000) {
    modifier = " Nonillion";
    finalquantity = quantity / 1000000000000000000000000000000;
  }
  if (quantity > 1000000000000000000000000000000000) {
    modifier = " Decillion";
    finalquantity = quantity / 1000000000000000000000000000000000;
  }
  if (quantity > 1000000000000000000000000000000000000) {
    modifier = " Undecillion";
    finalquantity = quantity / 1000000000000000000000000000000000000;
  }
  if (quantity > 1000000000000000000000000000000000000000) {
    modifier = " Duodecillion";
    finalquantity = quantity / 1000000000000000000000000000000000000000;
  }
  if (quantity > 1000000000000000000000000000000000000000000) {
    modifier = " Tredecillion";
    finalquantity = quantity / 1000000000000000000000000000000000000000000;
  }
  if (quantity > 1000000000000000000000000000000000000000000000) {
    modifier = " Quattuordecillion";
    finalquantity = quantity / 1000000000000000000000000000000000000000000000;
  }
  if (quantity > 1000000000000000000000000000000000000000000000000) {
    modifier = " Quindecillion";
    finalquantity =
      quantity / 1000000000000000000000000000000000000000000000000;
  }
  if (quantity > 1000000000000000000000000000000000000000000000000000) {
    modifier = " Sexdecillion";
    finalquantity =
      quantity / 1000000000000000000000000000000000000000000000000000;
  }
  if (quantity > 1000000000000000000000000000000000000000000000000000000) {
    modifier = " Septendecillion";
    finalquantity =
      quantity / 1000000000000000000000000000000000000000000000000000000;
  }
  if (quantity > 1000000000000000000000000000000000000000000000000000000000) {
    modifier = " Octodecillion";
    finalquantity =
      quantity / 1000000000000000000000000000000000000000000000000000000000;
  }
  if (
    quantity > 1000000000000000000000000000000000000000000000000000000000000
  ) {
    modifier = " Novemdecillion";
    finalquantity =
      quantity / 1000000000000000000000000000000000000000000000000000000000000;
  }
  if (
    quantity > 1000000000000000000000000000000000000000000000000000000000000000
  ) {
    modifier = " Vigintillion";
    finalquantity =
      quantity /
      1000000000000000000000000000000000000000000000000000000000000000;
  }
  if (
    quantity >
    1000000000000000000000000000000000000000000000000000000000000000000
  ) {
    modifier = " Unvigintillion";
    finalquantity =
      quantity /
      1000000000000000000000000000000000000000000000000000000000000000000;
  }
  if (
    quantity >
    1000000000000000000000000000000000000000000000000000000000000000000000
  ) {
    modifier = " Duovigintillion";
    finalquantity =
      quantity /
      1000000000000000000000000000000000000000000000000000000000000000000000;
  }
  if (
    quantity >
    1000000000000000000000000000000000000000000000000000000000000000000000000
  ) {
    modifier = " Trevigintillion";
    finalquantity =
      quantity /
      1000000000000000000000000000000000000000000000000000000000000000000000000;
  }
  if (
    quantity >
    1000000000000000000000000000000000000000000000000000000000000000000000000000
  ) {
    modifier = " Quattuorvigintillion";
    finalquantity =
      quantity /
      1000000000000000000000000000000000000000000000000000000000000000000000000000;
  }
  if (
    quantity >
    1000000000000000000000000000000000000000000000000000000000000000000000000000000
  ) {
    modifier = " Quinvigintillion";
    finalquantity =
      quantity /
      1000000000000000000000000000000000000000000000000000000000000000000000000000000;
  }
  if (
    quantity >
    1000000000000000000000000000000000000000000000000000000000000000000000000000000000
  ) {
    modifier = " Sexvigintillion";
    finalquantity =
      quantity /
      1000000000000000000000000000000000000000000000000000000000000000000000000000000000;
  }
  if (
    quantity >
    1000000000000000000000000000000000000000000000000000000000000000000000000000000000000
  ) {
    modifier = " Septenvigintillion";
    finalquantity =
      quantity /
      1000000000000000000000000000000000000000000000000000000000000000000000000000000000000;
  }
  if (
    quantity >
    1000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
  ) {
    modifier = " Octovigintillion";
    finalquantity =
      quantity /
      1000000000000000000000000000000000000000000000000000000000000000000000000000000000000000;
  }
  if (
    quantity >
    1000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
  ) {
    modifier = " Novemvigintillion";
    finalquantity =
      quantity /
      1000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000;
  }
  if (precision === undefined) {
    precision = 0;
    if (finalquantity < 10000) {
      precision = 1;
    }
    if (finalquantity < 1000) {
      precision = 2;
    }
    if (finalquantity < 100) {
      precision = 3;
    }
    if (finalquantity < 10) {
      precision = 4;
    }
  }
  if (precision === 0) {
    finalquantity = Math.floor(finalquantity);
  }
  return finalquantity.toFixed(precision) + modifier;
}

export function stripDecimals(str, num) {
  if (str.indexOf(".") > -1) {
    var left = str.split(".")[0];
    var right = str.split(".")[1];
    return left + "." + right.slice(0, num);
  } else {
    return str;
  }
}

export function formatTrxValue(trxstr) {
  return parseFloat(parseFloat(trxstr).toFixed(9));
}
