import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Mine from "./pages/Mine";

function App() {
  return (
    <Router>
      <Routes>
        <Route element={<Home />} path="/" />
        <Route element={<Mine />} path="/mine" />
      </Routes>
    </Router>
  );
}

export default App;
